html,
body {
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
  background: #eef0f8 !important;
}

body {
  padding-top: 56px;
}

.main {
  min-height: calc(100vh - 119px);
}

.rug-card-upload-button {
  display: none !important;
}

.navtop {
  background-color: #1f1e2e;
  box-shadow: grey 0px 2px 2px -2px;
}
.scrollable-modal-body {
  max-height: 500px; /* Set an appropriate maximum height for the body */
  overflow-y: auto;
}
.navbar-dark .navbar-nav .active > .nav-link {
  color: #fff;
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: #fff;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-underline .nav-link {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.875rem;
  color: var(--secondary);
}

.nav-underline .nav-link:hover {
  color: var(--blue);
}

.nav-underline .active {
  font-weight: 500;
  color: var(--gray-dark);
}

.navbar-nav {
  margin-left: auto !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5);
}

.bg-purple {
  background-color: var(--purple);
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.lh-100 {
  line-height: 1;
}
.lh-125 {
  line-height: 1.25;
}
.lh-150 {
  line-height: 1.5;
}

.header {
  background: #fff;
}

.footer {
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  line-height: 60px; /* Vertically center the text there */
  background-color: #494b4c;
}

.sidebar {
  background: #1f1e2e !important;
  position: fixed;
  top: 56px;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0px; /* Height of navbar */
  /*height: calc(100vh - 48px);*/
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #fff;
  margin-top: 10px;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #000;
  background: #fff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.notification {
  padding: 0px !important;
  background: none !important;
  box-shadow: none !important;
}

.app-btn {
  background-color: #41464b;
  color: #fff;
  border: 0;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.11);
}

.item-title {
  color: #000;
}

.app-btn-link {
  color: #000;
}

.app-btn-link:hover {
  color: #000;
}

.heading-text {
  color: #41464b;
}

.app-bg-color {
  background: #fff;
}

.form-control {
  border: 1px solid #e0e0e0 !important;
  height: 48px;
}

label {
  color: #05071e;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 17px;
  text-transform: uppercase;
}

.input-group-addon {
  position: absolute;
  right: 15px;
  top: 12px;
  z-index: 9;
}

button.btn {
  /* height: 56px; */
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  border-radius: 6px !important;
}
a {
  color: #0d6efd;
  text-decoration: none;
}

.clear-all {
  display: none !important;
}

.save-template {
  display: none !important;
}

@media (max-width: 767.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 56px; /* Height of navbar */
    bottom: 0;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    background-color: var(--gray-dark);
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  .offcanvas-collapse.open {
    -webkit-transform: translateX(-1rem);
    transform: translateX(-1rem); /* Account for horizontal padding on navbar */
  }
}

@media (min-width: 768px) and (min-width: 992px) {
  .footerauth {
    margin-left: 113px;
  }
}
